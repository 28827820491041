import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Notification from '../utils/notification';
import PrescriptionService from '../services/prescriptionService';
import { isEmpty, find } from 'lodash';
import SimpleReactValidator from 'simple-react-validator';
import { hasRole, hasProvider } from '../utils/auth';

class PatientBalanceFeeCollectionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            receive_amount: '',
            payment: "partial",
            status: "",
            payment_insurance_amount: "",
            payment_cash_amount: "",
            payment_card_amount: "",
            payment_online_amount: "",
            payment_remark: "",
            total_payment: 0,
            buttonText: 'Submit',
            isSubmitDisabled: false,
            show: false,
            isloading: false,
            balance: 0,
            totalValidationError: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validator = new SimpleReactValidator();
    }

    feeHandler = e => {

        this.setState({
            [e.target.name]: e.target.value
        });

        if (e.target.value === 'paid') {
            this.setState({
                show: true,
                buttonText: 'Submit',
            });
        } else if (e.target.value === 'unpaid') {
            this.setState({
                show: false,
                buttonText: 'Send',
            });
        } else if (e.target.value === 'full') {
            this.setState({
                receive_amount: this.props.balance,
                total_payment: 0,
                isFullPayment: true,
            });
        } else if (e.target.value === 'partial') {
            this.setState({
                receive_amount: "",
                total_payment: 0,
                isFullPayment: false,
            });
        }
    }

    receiveAmountHandler = e => {
        this.setState({
            ['receive_amount']: parseFloat(e.target.value)
        });
    }

    remarkHandler = e => {
        this.setState({
            ['payment_remark']: e.target.value
        });
    }

    handleTotalFees = (e) => {
        let receive_amount;
        if (isEmpty(this.state.receive_amount)) {
            receive_amount = 0;
        } else {
            receive_amount = parseFloat(this.state.receive_amount)
        }
        this.setState({
            total_payment: (receive_amount).toFixed(2)
        })
    }

    handleClose = () => {
        this.props.handleClose();
        this.validator.hideMessages();
        this.setState({
            receive_amount: "",
            status: "",
            payment_insurance_amount: "",
            payment_cash_amount: "",
            payment_card_amount: "",
            payment_online_amount: "",
            payment_remark: "",
            total_payment: 0,
            buttonText: 'Submit',
            isSubmitDisabled: false,
            show: false,
            isloading: false,
            currency: "",
        });
    }

    handleSubmit = (patient_id, e) => {
        e.preventDefault();

        const { payment_insurance_amount, payment_cash_amount, payment_card_amount, payment_online_amount, receive_amount } = this.state;
        if (this.state.status === "paid") {
            const totalCollected =
                parseFloat(payment_insurance_amount || 0) +
                parseFloat(payment_cash_amount || 0) +
                parseFloat(payment_card_amount || 0) +
                parseFloat(payment_online_amount || 0);

            if (totalCollected !== parseFloat(receive_amount)) {
                this.setState({ totalValidationError: true });
                return false;
            } else {
                this.setState({ totalValidationError: false });
            }
        }

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        const data = {
            receive_amount: this.state.receive_amount ? this.state.receive_amount : null,
            payment_insurance_amount: this.state.payment_insurance_amount ? this.state.payment_insurance_amount : null,
            payment_cash_amount: this.state.payment_cash_amount ? this.state.payment_cash_amount : null,
            payment_card_amount: this.state.payment_card_amount ? this.state.payment_card_amount : null,
            payment_online_amount: this.state.payment_online_amount ? this.state.payment_online_amount : null,
            status: this.state.status,
            payment_remark: this.state.payment_remark ? this.state.payment_remark : null
        }

        if (hasProvider(this.props.selected_user_profile.service_provider, ['pathology'])) {
            data['type'] = "lims";
        }
        this.setState({
            isloading: true
        });
        let message = this.state.status === 'paid' ? 'Payment done.' : 'Sent to receptionist/nurse'
        PrescriptionService.collectPatientBalanceFeeInfo(patient_id, data).then(resp => {
            Notification.show('success', { message: message });
            this.props.onSuccess(this.props);
            this.handleClose()
        }).catch(error => {
            this.setState({
                isloading: false,
                buttonText: 'Send'
            });
            Notification.show('error', error.response.data)
        })
    }
    showBlock = (selected_user_profile) => {
        if (!hasRole(selected_user_profile, ["associate-doctor"])) {
            return true
        } else {
            if (hasRole(selected_user_profile, ["associate-doctor"]) && selected_user_profile.is_procedure_listed)
                return true
        }
        return false
    }

    render() {
        this.validator.purgeFields();
        let { selected_user_profile } = this.props
        return (
            <React.Fragment>
                {this.state.isloading && this.state.isloading && <div className="loading">Loading</div>}
                <Modal
                    size='xl' centered aria-labelledby="contained-modal-title-vcenter"
                    show={this.props.show}
                    onHide={this.handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <h6>Fee Collection</h6>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <ul className="fee_collection_form">
                                <li class="w-100"><h5>Balance Amount: <b>${this.props.balance}</b></h5></li>

                                <li class="w-100"><span>
                                    <input type="radio" id="partial" name="payment" value="partial" className="form-control" onChange={this.feeHandler} checked={this.state.payment === "partial"} />
                                    <label htmlFor="partial">Partial</label>
                                    <input type="radio" id="full" name="payment" value="full" className="form-control" onChange={this.feeHandler} checked={this.state.payment === "full"} />
                                    <label htmlFor="full">Full Balance Clear</label></span>
                                    {this.validator.message('Payment', this.state.payment, 'required')}
                                </li>

                                {this.state.payment && <li class="w-50">Amount Received:
                                    <span>
                                        <input id="receive_amount" type="number" name="receive_amount" value={this.state.receive_amount} className="form-control" onChange={this.receiveAmountHandler} onBlur={this.handleTotalFees} disabled={this.state.isFullPayment} />
                                    </span>
                                    {this.validator.message('Amount Receive', this.state.receive_amount, 'required|numeric|min:1,num|max:' + this.props.balance + ',num',)}
                                </li>
                                }



                                {this.showBlock(selected_user_profile) ? <>
                                    {hasProvider(this.props.selected_user_profile.service_provider, ['pathology']) ? <li style={{ display: "block" }}>Status: <span>
                                        <input type="radio" id="paid" name="status" value="paid" className="form-control" onChange={this.feeHandler} />
                                        <label htmlFor="paid">Collect Now</label></span>
                                        {this.validator.message('Status', this.state.status, 'required')}
                                    </li>
                                        :
                                        <li style={{ display: "block" }}>Status: <span>
                                            <input type="radio" id="paid" name="status" value="paid" className="form-control" onChange={this.feeHandler} />
                                            <label htmlFor="paid">Collected</label>
                                            <input type="radio" id="unpaid" name="status" value="unpaid" className="form-control" onChange={this.feeHandler} />
                                            <label htmlFor="unpaid">Send to Receptionist/Nurse</label></span>
                                            {this.validator.message('Status', this.state.status, 'required')}
                                        </li>
                                    }

                                    <div>
                                        {this.state.show && <li>From Insurance: <span><input type="number" name="payment_insurance_amount" className="form-control" onChange={this.feeHandler} /></span>
                                            {this.validator.message('From Insurance', this.state.payment_insurance_amount, 'required|numeric|min:0,num|max:10')}
                                        </li>}

                                        {this.state.show && <li>From Card: <span><input type="number" name="payment_card_amount" className="form-control" onChange={this.feeHandler} /></span>
                                            {this.validator.message('From Card', this.state.payment_card_amount, 'required|numeric|min:0,num|max:10')}
                                        </li>}

                                        {this.state.show && <li>From Cash: <span><input type="number" name="payment_cash_amount" className="form-control" onChange={this.feeHandler} /></span>
                                            {this.validator.message('From Cash', this.state.payment_cash_amount, 'required|numeric|min:0,num|max:10')}
                                        </li>}

                                        {this.state.show && <li>From Online: <span><input type="number" name="payment_online_amount" className="form-control" onChange={this.feeHandler} /></span>
                                            {this.validator.message('From Online', this.state.payment_online_amount, 'required|numeric|min:0,num|max:10')}
                                        </li>}
                                    </div>
                                    <li>{this.state.totalValidationError &&
                                        <div className="srv-validation-message w-100">
                                            The total amount does not match the received amount.
                                        </div>}
                                    </li>
                                    <li style={{ display: "block" }}>Remaining Balance: <span>{(this.state.receive_amount > this.props.balance) ? 0 : (this.state.receive_amount > 0 ? "$" + (this.props.balance - this.state.receive_amount) : "$0")}</span></li>

                                    {this.state.show && <li className="full-width">Remark: <span>
                                        <textarea name="payment_remark" className="form-control" onChange={this.remarkHandler} /></span>
                                        {this.validator.message('Remark', this.state.payment_remark, 'required')}
                                    </li>}

                                    <li>{this.validator.message('Balance', this.state.balance, 'min:0,num', {
                                        messages: {
                                            min: 'Collected amount should not be exceed the Total amount.',
                                        }, className: 'srv-validation-message-custom'
                                    })}</li>

                                </> :

                                    <li>Status: <span>
                                        <input type="radio" id="unpaid" name="status" value="unpaid" className="form-control" onChange={this.feeHandler} />
                                        <label htmlFor="unpaid">Send to Receptionist/Nurse</label></span>
                                        {this.validator.message('Status', this.state.status, 'required')}
                                    </li>
                                }

                            </ul>

                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" disabled={this.state.isSubmitDisabled} onClick={e => this.handleSubmit(this.props.patientId, e)}>
                            {this.state.buttonText}
                        </Button>
                        <Button variant="secondary" disabled={this.state.isSubmitDisabled} onClick={this.handleClose}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}

export default PatientBalanceFeeCollectionModal
