import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Notification from '../utils/notification';
import PrescriptionService from '../services/prescriptionService';
import ProcedureService from '../services/procedureService';
import CurrencyService from '../services/currencyService';
import { isEmpty, find } from 'lodash';
import { getDateTimeFormat } from '../utils/helpers';
import SimpleReactValidator from 'simple-react-validator';
import { hasRole } from '../utils/auth';
import Select from 'react-select';

class PatientFeeCollectionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            consultancy_fee: "",
            status: "",
            payment_insurance_amount: "",
            payment_cash_amount: "",
            payment_card_amount: "",
            payment_online_amount: "",
            payment_remark: "",
            total_payment: 0,
            buttonText: 'Submit',
            isSubmitDisabled: false,
            show: false,
            isloading: false,
            procedures: "",
            selectedProcedures: [{ id: "", name: "", price: 0 }],
            currencies: "",
            currency: {},
            defaultCurrency: 1,
            balance: 0
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
        this.setState({
            isloading: true
        });
        this.getProcedureList();
        this.getCurrencyList();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show && this.props.show == true) {
            this.setDefaultCurrency();
        }
    }

    getProcedureList = () => {
        ProcedureService.listAll().then(resp => {
            this.setState({
                procedures: resp.data.data,
                isloading: false
            });
        }).catch(error => {
            this.setState({
                isloading: false,
                buttonText: 'Submit'
            });
            Notification.show('error', error.response.data)
        })
    }

    getCurrencyList = () => {
        CurrencyService.listAll().then(resp => {
            this.setState({
                currencies: resp.data.data,
                isloading: false
            }, () => {
                this.setDefaultCurrency();
            });
        }).catch(error => {
            this.setState({
                isloading: false,
                buttonText: 'Submit'
            });
            Notification.show('error', error.response.data)
        })
    }

    setDefaultCurrency = () => {
        let currency = this.state.currencies.filter((currency) => currency.id == this.state.defaultCurrency);
        this.setState({ currency: currency[0] });
    }

    handleSelectCurrency = (e) => {
        let currency = this.state.currencies.filter((currency) => currency.id == e.target.value)
        this.setState({ currency: currency[0] }, () => {
            this.changePrice();
        });
    };

    changePrice = () => {
        let procedures = this.state.selectedProcedures.map(procedure => {
            if (procedure.id != "") {
                let procedureValue = this.state.procedures.filter((p) => p.id == procedure.id)
                let currencyPrice = procedureValue[0].prices.filter((price) => price.currency.id == this.state.currency.id)
                let newPrice = currencyPrice[0].price;
                return { id: procedure.id, name: procedure.name, price: newPrice }
            }
            else {
                return procedure;
            }
        });

        this.setState({ selectedProcedures: procedures }, () => {
            this.handleTotalFees();
        });
    };

    handleSelectProcedure = (e, idx) => {
        const currency = this.state.currency.id;
        const value = e.value;
        let selectedProcedures = this.state.selectedProcedures;
        if (value > 0) {
            let procedure = find(this.state.procedures, { 'id': value });
            var p_price = procedure.prices.filter((price) => price.currency.id == currency)
            procedure = { id: procedure.id, name: procedure.name, price: p_price[0].price }
            selectedProcedures[idx] = procedure;
        } else {
            selectedProcedures[idx] = { id: "", name: "", price: 0 };
        }
        this.setState({ selectedProcedures: selectedProcedures }, () => {
            this.handleTotalFees();
        })
    }

    feeHandler = e => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.setTotalBalance();
        });
        if (e.target.value === 'paid') {
            this.setState({
                show: true,
                buttonText: 'Submit',
            });
        } else if (e.target.value === 'unpaid') {
            this.setState({
                show: false,
                buttonText: 'Send',
            });
        }
    }

    setTotalBalance = () => {
        let payment_insurance_amount = this.state.payment_insurance_amount ? parseFloat(this.state.payment_insurance_amount) : 0;
        let payment_cash_amount = this.state.payment_cash_amount ? parseFloat(this.state.payment_cash_amount) : 0;
        let payment_card_amount = this.state.payment_card_amount ? parseFloat(this.state.payment_card_amount) : 0;
        let payment_online_amount = this.state.payment_online_amount ? parseFloat(this.state.payment_online_amount) : 0;
        this.setState({
            'balance': (this.state.total_payment - (payment_insurance_amount + payment_cash_amount + payment_card_amount + payment_online_amount)).toFixed(2)
        });
    }

    handleTotalFees = (e) => {
        let consultancy_fee, procedure_fee;
        if (isEmpty(this.state.consultancy_fee)) {
            consultancy_fee = 0;
        } else {
            consultancy_fee = parseFloat(this.state.consultancy_fee)
        }

        procedure_fee = this.state.selectedProcedures.reduce((total, obj) => parseFloat(obj.price) + parseFloat(total), 0)

        this.setState({
            total_payment: (consultancy_fee + procedure_fee).toFixed(2)
        }, () => {
            this.setTotalBalance();
        })
    }

    handleClose = () => {
        this.props.handleClose();
        this.validator.hideMessages();
        this.setState({
            consultancy_fee: "",
            status: "",
            payment_insurance_amount: "",
            payment_cash_amount: "",
            payment_card_amount: "",
            payment_online_amount: "",
            payment_remark: "",
            total_payment: 0,
            buttonText: 'Submit',
            isSubmitDisabled: false,
            show: false,
            isloading: false,
            currency: "",
            selectedProcedures: [{ id: "", name: "", price: 0 }]
        });
    }

    handleSubmit = (patient_id, e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        let procedure_data = this.state.selectedProcedures.filter((procedure) => procedure.id != "")

        const data = {
            consultancy_fee: this.state.consultancy_fee ? this.state.consultancy_fee : null,
            payment_insurance_amount: this.state.payment_insurance_amount ? this.state.payment_insurance_amount : null,
            payment_cash_amount: this.state.payment_cash_amount ? this.state.payment_cash_amount : null,
            payment_card_amount: this.state.payment_card_amount ? this.state.payment_card_amount : null,
            payment_online_amount: this.state.payment_online_amount ? this.state.payment_online_amount : null,
            status: this.state.status,
            payment_remark: this.state.payment_remark ? this.state.payment_remark : null,
            procedure_data: procedure_data,
            currency_id: this.state.currency ? this.state.currency.id : null
        }
        this.setState({
            isloading: true
        });
        let message = this.state.status === 'paid' ? 'Payment done.' : 'Sent to receptionist/nurse'
        PrescriptionService.createPatientFeeInfo(patient_id, data).then(resp => {
            Notification.show('success', { message: message });
            this.props.onSuccess(this.props);
            this.handleClose()
        }).catch(error => {
            this.setState({
                isloading: false,
                buttonText: 'Send'
            });
            Notification.show('error', error.response.data)
        })
    }
    showBlock = (selected_user_profile) => {
        if (!hasRole(selected_user_profile, ["associate-doctor"])) {
            return true
        } else {
            if (hasRole(selected_user_profile, ["associate-doctor"]) && selected_user_profile.is_procedure_listed)
                return true
        }
        return false
    }

    handleAddMoreProcedure = () => {
        this.setState({
            selectedProcedures: this.state.selectedProcedures.concat({
                id: '',
                name: '',
                price: 0
            })
        });
    }

    handleRemoveProcedure = (event) => {
        this.validator.hideMessages();
        const idx = Number(event.target.dataset["id"]);
        const selectedProcedures = this.state.selectedProcedures.filter((s, sidx) => idx !== sidx);
        this.setState({ selectedProcedures: selectedProcedures }, () => {
            this.handleTotalFees()
        });
    };

    render() {
        this.validator.purgeFields();
        let { selected_user_profile } = this.props
        let { procedures } = this.state
        let options = []
        procedures && procedures.map(procedure => {
            options.push({ 'value': procedure.id, 'label': procedure.name })
        })
        return (
            <React.Fragment>
                {this.state.isloading && this.state.isloading && <div className="loading">Loading</div>}
                <Modal
                    size='xl' centered aria-labelledby="contained-modal-title-vcenter"
                    show={this.props.show}
                    onHide={this.handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <h6>Fee Collection</h6>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <ul className="fee_collection_form">
                                <li>Select Currency: <span>
                                    <select name="currency" className="form-control" onChange={this.handleSelectCurrency} id="currency">
                                        <option value="0" disabled>Select Currency</option>
                                        {this.state.currencies && this.state.currencies.map(currency => (
                                            <option key={currency.id} value={currency.id} selected={currency.id == this.state.currency.id}>{currency.name}</option>
                                        ))}
                                    </select>
                                    {this.validator.message('Currency', this.state.currency, 'required')}
                                </span>
                                </li> <br />
                                <li>Consultancy Fee: <span><input id="consultancy_fee" type="number" name="consultancy_fee" className="form-control" onChange={this.feeHandler} onBlur={this.handleTotalFees} /></span>
                                    {this.validator.message('Consultancy Fee', this.state.consultancy_fee, 'required|numeric|min:0,num|max:10')}
                                </li>

                                <div>
                                    {this.state.selectedProcedures.map((selectedProcedure, idx) => (
                                        <div className='w-100'>
                                            <li>Select Procedure: <span>
                                                <Select className="basic-single" name="procedure_name" options={options}
                                                    value={selectedProcedure.id ? options.find(obj => selectedProcedure.id == obj.value) : ''}
                                                    onChange={e => this.handleSelectProcedure(e, idx)}>
                                                </Select>
                                                {this.validator.message('Procedure', selectedProcedure.id, 'required')}
                                            </span>
                                            </li>
                                            {this.showBlock(selected_user_profile) ? <>
                                                <li>Procedure Cost: <span>{selectedProcedure.price ? ((this.state.currency ? (this.state.currency.name + ' ' + this.state.currency.sign + ' ') : ' ') + selectedProcedure.price) : ""}</span>
                                                </li>
                                            </> : ""}
                                            {idx !== 0 && <a href className="cancel" data-id={idx} onClick={this.handleRemoveProcedure} > </a>}
                                        </div>
                                    ))
                                    }

                                    <li className='w-100'>
                                        <button className="btn btn-primary float-right" type="button" onClick={this.handleAddMoreProcedure}>Add More</button>
                                    </li>
                                </div>

                                {this.showBlock(selected_user_profile) ? <>

                                    <li>Total to be collected: <span>{this.state.total_payment}</span></li>
                                    <li>Status: <span>
                                        <input type="radio" id="paid" name="status" value="paid" className="form-control" onChange={this.feeHandler} />
                                        <label htmlFor="paid">Collected</label>
                                        <input type="radio" id="unpaid" name="status" value="unpaid" className="form-control" onChange={this.feeHandler} />
                                        <label htmlFor="unpaid">Send to Receptionist/Nurse</label></span>
                                        {this.validator.message('Status', this.state.status, 'required')}
                                    </li>

                                    {this.state.show && <li>From Insurance: <span><input type="number" name="payment_insurance_amount" className="form-control" onChange={this.feeHandler} /></span>
                                        {this.validator.message('From Insurance', this.state.payment_insurance_amount, 'required|numeric|min:0,num|max:10')}
                                    </li>}

                                    {this.state.show && <li>From Card: <span><input type="number" name="payment_card_amount" className="form-control" onChange={this.feeHandler} /></span>
                                        {this.validator.message('From Card', this.state.payment_card_amount, 'required|numeric|min:0,num|max:10')}
                                    </li>}

                                    {this.state.show && <li>From Cash: <span><input type="number" name="payment_cash_amount" className="form-control" onChange={this.feeHandler} /></span>
                                        {this.validator.message('From Cash', this.state.payment_cash_amount, 'required|numeric|min:0,num|max:10')}
                                    </li>}

                                    {this.state.show && <li>From Online: <span><input type="number" name="payment_online_amount" className="form-control" onChange={this.feeHandler} /></span>
                                        {this.validator.message('From Online', this.state.payment_online_amount, 'required|numeric|min:0,num|max:10')}
                                    </li>}

                                    {this.state.show && <li>Balance: {this.state.balance}</li>}

                                    {this.state.show && <li className="full-width">Remark: <span>
                                        <textarea name="payment_remark" className="form-control" onChange={this.feeHandler} /></span>
                                        {this.validator.message('Remark', this.state.payment_remark, 'required')}
                                    </li>}

                                    <li>{this.validator.message('Balance', this.state.balance, 'min:0,num', {
                                        messages: {
                                            min: 'Collected amount should not be exceed the Total amount.',
                                        }, className: 'srv-validation-message-custom'
                                    })}</li>

                                </> :

                                    <li>Status: <span>
                                        <input type="radio" id="unpaid" name="status" value="unpaid" className="form-control" onChange={this.feeHandler} />
                                        <label htmlFor="unpaid">Send to Receptionist/Nurse</label></span>
                                        {this.validator.message('Status', this.state.status, 'required')}
                                    </li>
                                }

                            </ul>

                        </Form>

                        {this.showBlock(selected_user_profile) ? <>
                            <div className='c-scroll'>
                                <table className="table table-bordered c-line-break">
                                    <thead>
                                        <tr>
                                            <th>Date and Time</th>
                                            <th>Collected By</th>
                                            <th>Currency</th>
                                            <th>Consultancy Fee</th>
                                            <th>Other Fee</th>
                                            <th>Procedure Fee</th>
                                            <th>Insurance</th>
                                            <th>Card</th>
                                            <th>Cash</th>
                                            <th>Online</th>
                                            <th>Balance</th>
                                            <th>Balance Received</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.patientFeeInfo && !isEmpty(this.props.patientFeeInfo.data) ? this.props.patientFeeInfo.data.map((row, index) =>
                                            <tr key={index}>
                                                <td>{getDateTimeFormat(row.created_at)}</td>
                                                <td>{row.collected_by}</td>
                                                <td>{row.currency.name}</td>
                                                <td>{row.consultancy_fee}</td>
                                                <td>{row.other_fee}</td>
                                                <td>{row.procedure_fee && row.procedure_fee}</td>
                                                <td>{row.from_insurance}</td>
                                                <td>{row.payment_card_amount}</td>
                                                <td>{row.cash_collected}</td>
                                                <td>{row.payment_online_amount}</td>
                                                <td>{row.balance}</td>
                                                <td>{row.balance_received}</td>
                                            </tr>
                                        ) : <tr>No Record Found</tr>}
                                    </tbody>
                                </table>
                            </div>
                        </> : ''}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" disabled={this.state.isSubmitDisabled} onClick={e => this.handleSubmit(this.props.patientId, e)}>
                            {this.state.buttonText}
                        </Button>
                        <Button variant="secondary" disabled={this.state.isSubmitDisabled} onClick={this.handleClose}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}

export default PatientFeeCollectionModal
