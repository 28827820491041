import React from "react";
import Notification from '../utils/notification';
import PatientBalanceFeeCollectionModal from "./PatientBalanceFeeCollectionModal";
import PrescriptionService from '../services/prescriptionService';

class PatientBalanceFeeCollectButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			prescriptionId: '',
			type: '',
			isloading: false,
			balance: '',
			showCollectFeeModal: this.props.showCollectFeeModal ? this.props.showCollectFeeModal : false,
		}
		this.onSuccessRedirect = this.onSuccessRedirect.bind(this);
		this.showCollectFeeModal = this.showCollectFeeModal.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.props.showCollectFeeModal && this.showCollectFeeModal()
	}

	componentDidMount() {
		this.setState({
			prescriptionId: this.props.prescriptionId,
			type: this.props.type,
			balance: this.props.balance
		});
	}

	getPatientFeeInfo = () => {
		PrescriptionService.getPatientFeeInfo(this.props.patientId).then(resp => {
			this.setState({
				consultancy_fee: "",
				other_fee: "",
				payment_insurance_amount: "",
				payment_cash_amount: "",
				status: "",
				payment_remark: "",
				payment_card_amount: "",
				payment_online_amount: "",
				total_payment: 0
			})
			this.setState({
				isloading: false
			});
			this.setState({ savedPatientFeeInfo: resp.data })
		}).catch(error => {
			this.setState({
				isloading: false,
				buttonText: 'Send'
			});
			Notification.show('error', error.response.data)
		})
	}

	onSuccessRedirect() {
		this.setState({ showCollectFeeModal: false });
		this.props.success();
	}

	showCollectFeeModal() {
		this.getPatientFeeInfo();
		this.setState({ showCollectFeeModal: true });
	}

	handleClose() {
		this.setState({ showCollectFeeModal: false });
	}

	render() {

		return (<React.Fragment>
			{this.state.isloading && this.state.isloading && <div className="loading">Loading</div>}
			<button className="btn btn-danger ml-2" onClick={e => this.showCollectFeeModal()} title="Collect Balance Fee">
				Balance ${this.state.balance > 0 ? this.state.balance : ""}
			</button>
			<PatientBalanceFeeCollectionModal
				onSuccess={this.onSuccessRedirect}
				show={this.state.showCollectFeeModal}
				handleClose={this.handleClose}
				patientId={this.props.patientId}
				patientFeeInfo={this.state.savedPatientFeeInfo}
				selected_user_profile={this.props.selected_user_profile}
				balance={this.state.balance}
			/>
		</React.Fragment>)
	}
}

export default PatientBalanceFeeCollectButton;